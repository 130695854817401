<script lang="ts" setup>
const { t } = useT();

const props = withDefaults(
	defineProps<{
		value?: number;
		maxValue?: number;
	}>(),
	{
		value: 0,
		maxValue: 100
	}
);
const pointsLeft = computed(() => props.maxValue - props.value);
const progressValue = computed(() => {
	if (pointsLeft.value === 0) {
		return "100%";
	}
	if (props.value !== 0) {
		return `${(props.value * 100) / props.maxValue}%`;
	}
	return "26px";
});
</script>

<template>
	<div class="progress-wrap">
		<div class="icon-wrapper">
			<NuxtIcon class="icon-money" name="20/entries" filled />
		</div>
		<div class="progress">
			<div class="progress-active" />
		</div>
		<AText class="value text-chaozhou" :modifiers="['bold', 'uppercase']" as="div">
			<span v-if="pointsLeft > 0">{{ `${numberFormat(value)}/${numberFormat(maxValue)} ${t("Collected")}` }}</span>
			<span v-else>{{ t("Done") }}</span>
		</AText>
	</div>
</template>

<style scoped lang="scss">
.progress-wrap {
	position: relative;

	.progress {
		background: var(--concepcion);
		border-radius: 20px;
		padding: 4px 4px 4px 24px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		&-active {
			width: v-bind(progressValue);
			height: 20px;
			background: var(--gatchina);
			border-radius: inherit;
			transition: all 500ms ease-in-out;
		}
	}

	.value {
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		white-space: nowrap;
		line-height: 1.1;
	}

	.icon-wrapper {
		height: 38px;
		width: 38px;
		position: absolute;
		top: calc(50% - 19px);
		left: 0;
		background: var(--guiguinto);
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		filter: drop-shadow(4px 0 4px var(--chuncheon));
	}

	.icon-money {
		display: inline-flex;
		background: var(--changsha);
		border-radius: 50%;
		height: 32px;
		width: 32px;
		font-size: 37px;

		&:deep(svg) {
			margin: 0;
		}
	}
}
</style>
